import { styled } from '@mui/material/styles';
import { Container, Paper } from '@mui/material';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#87CEEB',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const ProfileContainer = ({ canvasId, children }) => {
  return (
    <StyledContainer id={canvasId} maxWidth="md">
      <StyledPaper elevation={3}>{children}</StyledPaper>
    </StyledContainer>
  );
};

export default ProfileContainer;
