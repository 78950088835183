import React, { createRef, useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Fade,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material';
import useStore from '../store';
import RandomSelectButton from '../RandomSelectButton';

const TestPage = () => {
  const { user, questions, scores, setScores, licenseKey, api } = useStore(
    (state) => state,
  );
  const questionRefs = useRef([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  useEffect(() => {
    api.getQuestions(licenseKey);
    // 로컬 스토리지에서 데이터 불러오기
    const savedData = localStorage.getItem('_qscs');
    if (savedData) {
      const { licenseKey: savedLicenseKey, scores: savedScores } =
        JSON.parse(savedData);

      // 현재 컴포넌트의 라이센스 키와 저장된 라이센스 키가 일치하는 경우에만 상태 업데이트
      if (licenseKey === savedLicenseKey) {
        setScores(savedScores);
      }
    }
  }, [licenseKey]);
  useEffect(() => {
    questionRefs.current = questions.map(
      (_, index) => questionRefs.current[index] || createRef(),
    );
  }, [questions]);

  const handleScoreChange = (questionId, score, index) => {
    const newScores = { ...scores, [questionId]: score };
    setScores(newScores);
    // scores 업데이트 후 로컬 스토리지에 저장
    localStorage.setItem(
      '_qscs',
      JSON.stringify({ licenseKey, scores: newScores }),
    );
    if (activeQuestionIndex < questions.length - 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const goToNextQuestion = () => {
    if (activeQuestionIndex < questions.length - 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const goToPreviousQuestion = () => {
    if (activeQuestionIndex > 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
  };
  const AnswerButton = ({ score, index, questionId }) => (
    <Button
      key={`${index}-${score}`}
      variant={scores[questionId] === score ? 'contained' : 'outlined'}
      color="primary"
      onClick={() => handleScoreChange(questionId, score, index)}
      sx={{
        height: '48px', // 버튼의 높이를 48px로 설정
        padding: '0 16px', // 버튼의 좌우 패딩
        fontSize: '1rem', // 텍스트 크기, 필요에 따라 조정
      }}
    >
      {score}
    </Button>
  );

  const AnswerButtonContainer = ({ MAX_POINT, QUESTIONS_ID, index }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row', // 가로 방향으로 정렬
        flexWrap: 'wrap', // 여러 줄로 나누기
        justifyContent: 'center', // 중앙 정렬
        '& > button': {
          m: 1,
          width: MAX_POINT > 5 ? `${100 / 6}%` : '100%', // MAX_POINT가 5 초과일 때 각 버튼의 너비를 화면의 1/6로 설정
          height: '48px', // 모든 버튼의 높이를 동일하게 설정
        },
      }}
    >
      {Array.from({ length: MAX_POINT }, (_, scoreIndex) => scoreIndex + 1).map(
        (score) => (
          <AnswerButton
            key={`${index}-${score}`}
            score={score}
            index={index}
            questionId={QUESTIONS_ID}
          />
        ),
      )}
    </Box>
  );

  const answeredQuestionsCount = Object.keys(scores).length; // 응답한 문항 수
  const progress = (answeredQuestionsCount / questions.length) * 100; // 진도율 계산

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          fontFamily: 'Noto Sans KR, sans-serif',
        }}
      >
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">
              {user.name}님의 에니어그램 검사
            </Typography>
            {process.env.NODE_ENV !== 'production' && <RandomSelectButton />}
          </Toolbar>
        </AppBar>

        <Box sx={{ width: '100%', my: 2 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: 10, backgroundColor: '#eeeeee' }}
          />
          <Typography
            variant="h6"
            fontWeight="bolder"
            align="center"
            sx={{ mt: 1 }}
          >
            진행 상태: {answeredQuestionsCount} / {questions.length} 문항
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          {questions.map(
            ({ QUESTIONS_ID, ORIGIN_ORDER, Q_CONTENTS, MAX_POINT }, index) => (
              <Fade
                in={index === activeQuestionIndex}
                timeout={250}
                key={index}
              >
                <Card
                  sx={{
                    mb: 2,
                    borderRadius: 4,
                    backgroundColor: 'rgba(236, 239, 241, 0.5)',
                    position: 'absolute',
                    width: '100%', // 모든 화면 크기에 대해 너비를 100%로 설정
                    '@media (min-width: 600px)': {
                      width: MAX_POINT <= 5 ? '60%' : '100%',
                      maxWidth: 900,
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ wordBreak: 'keep-all' }}
                    >
                      {ORIGIN_ORDER}. {Q_CONTENTS}
                    </Typography>
                    <AnswerButtonContainer
                      MAX_POINT={MAX_POINT}
                      QUESTIONS_ID={QUESTIONS_ID}
                      index={index}
                    />
                  </CardContent>

                  <CardActions
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'transparent',
                      p: 1,
                    }}
                  >
                    <Box sx={{ width: '33%', textAlign: 'left' }}>
                      {activeQuestionIndex > 0 && (
                        <Button
                          variant="contained"
                          onClick={goToPreviousQuestion}
                        >
                          이전
                        </Button>
                      )}
                    </Box>

                    <Box sx={{ width: '33%', textAlign: 'center' }}>
                      {answeredQuestionsCount - questions.length === 0 ? (
                        <Button
                          variant="contained"
                          onClick={() => api.makeResult(scores, licenseKey)}
                        >
                          결과 보기
                        </Button>
                      ) : (
                        <Button variant="contained" disabled>
                          결과 보기
                        </Button>
                      )}
                    </Box>

                    <Box sx={{ width: '33%', textAlign: 'right' }}>
                      {activeQuestionIndex < questions.length - 1 && (
                        <Button variant="contained" onClick={goToNextQuestion}>
                          다음
                        </Button>
                      )}
                    </Box>
                  </CardActions>
                </Card>
              </Fade>
            ),
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default TestPage;
