import { Box, Grid, Typography } from '@mui/material';
import useStore from '../../store';
import { Bar, Radar } from 'react-chartjs-2';
import chartjs from 'chart.js/auto'; // 차트쓰는동안 지우면 안됨
import ProfileContainer from '../../components/profile/ProfileContainer';
import ProfileLabelTitle from '../../components/profile/ProfileLabelTitle';
import { kemiLogoSrc } from '../../utils/constants';

// 특정 패턴에 맞는 키를 필터링하여 점수 객체를 반환하는 함수
const filterScoresByPattern = (scores, pattern) =>
  Object.fromEntries(
    Object.entries(scores).filter(([key]) => pattern.test(key)),
  );

function energeCenterFn(type) {
  const energeCenter = type
    .replace(/2|3|4/, '가슴')
    .replace(/5|6|7/, '머리')
    .replace(/8|9|1/, '장');
  return energeCenter;
}

const getSubType = (type) => {
  switch (type) {
    case 'SP':
      return '자기보존적';
    case 'SX':
      return '성적';
    case 'SO':
      return '사회적';
    case 'Re':
      return '반응';
    case 'Co':
      return '능력';
    case 'Po':
      return '긍정';
    default:
      return type; // 기본값은 원래의 type을 그대로 반환
  }
};

const ChartComponent = ({ typeMap }) => {
  const types = Object.keys(typeMap);
  const typeScores = Object.values(typeMap);
  const maxScore = Math.max(...typeScores);

  const backgroundColors = typeScores.map((score) =>
    score === maxScore ? 'rgba(255, 99, 132, 0.2)' : 'rgba(54, 162, 235, 0.2)',
  );
  const borderColors = typeScores.map((score) =>
    score === maxScore ? 'rgba(255, 99, 132, 1)' : 'rgba(54, 162, 235, 1)',
  );

  const data = {
    labels: types,
    datasets: [
      {
        label: 'Score',
        data: typeScores,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};
const RadarChartComponent = ({ typeMap }) => {
  const typeScores = Object.values(typeMap);
  const data = {
    labels: ['Pf', 'Hp', 'Ac', 'In', 'Ob', 'Lo', 'Ep', 'Ch', 'Pm'],
    datasets: [
      {
        label: 'Personality Factors',
        data: typeScores,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        fill: true,
      },
    ],
  };

  const options = {
    // 레이더 차트 옵션 설정
  };

  return <Radar data={data} options={options} />;
};

const gridItemStyle = {
  border: '1px solid black',
  padding: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const Page1 = ({ canvasId }) => {
  const { result, user } = useStore((state) => state);
  const { typeMap, enneagramTypes, enneagramSubTypes } = result;
  const mainTypeMap = filterScoresByPattern(typeMap, /^\d+$/);
  const subTypeMap = filterScoresByPattern(typeMap, /^(SP|SX|SO)$/);
  const subAglTypeMap = filterScoresByPattern(typeMap, /^(Re|Co|Po)$/);
  const { subTypes = [], subAglTypes = [] } = enneagramSubTypes;
  return (
    <ProfileContainer canvasId={canvasId}>
      <Typography variant="h4" align="center" gutterBottom>
        KEMI Enneagram Profile
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <img src={kemiLogoSrc} alt="로고 이미지" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>
            에니어그램은 여러 고대 전통의 영적 지혜에서 비롯되었으며 현대
            심리학과의 결합으로 오늘날 인간이해와 성숙의 도구로 활용 되고
            있습니다.
          </Typography>
          <Typography>
            한국에니어마인드연구소에서는 에니어그램을 통하여 부정적으로 움직이는
            마음의 역동을 깨닫게 하고, 스스로 본질을(진아) 회복해 낼 수 있도록
            안내하고 있습니다.
          </Typography>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>이름</Typography>
            <Typography>{user.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>이메일</Typography>
            <Typography>{user.email}</Typography>
          </Grid>
        </Grid>
      </Box>

      <ProfileLabelTitle title="나의 유형" />

      <Box mt={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ChartComponent typeMap={mainTypeMap} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RadarChartComponent typeMap={mainTypeMap} />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ProfileLabelTitle title="하위유형" />
          <ChartComponent typeMap={subTypeMap} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProfileLabelTitle title="갈등대처방식" />
          <ChartComponent typeMap={subAglTypeMap} />
        </Grid>
      </Grid>

      <ProfileLabelTitle title="나의 프로파일" />

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={24} sm={12} md={8} sx={gridItemStyle}>
            <Typography>
              성격유형 :{' '}
              {enneagramTypes.map(({ mainType }, index) => (
                <span key={index}>
                  {mainType + (enneagramTypes.length > 1 ? ',' : '')}
                </span>
              ))}
              유형
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={gridItemStyle}>
            <Typography>
              힘의중심 :{' '}
              {enneagramTypes.map(({ mainType }, idx) => (
                <span key={idx}>
                  {energeCenterFn(mainType) +
                    (enneagramTypes.length > 1 ? ',' : '')}
                </span>
              ))}
              중심
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={gridItemStyle}>
            <Typography>
              본능성향 :{' '}
              {subTypes.map((val, idx) => (
                <span key={idx}>
                  {getSubType(val) + (subTypes.length > 1 ? ', ' : ' ')}
                </span>
              ))}
              본능
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={gridItemStyle}>
            <Typography>
              날개성향 :{' '}
              {enneagramTypes.map(({ mainType, wingType }, idx) => (
                <span key={idx}>
                  {mainType +
                    'w' +
                    wingType +
                    (enneagramTypes.length > 1 ? ',' : '')}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={gridItemStyle}>
            <Typography>
              갈등대처 :{' '}
              {subAglTypes.map((val, idx) => (
                <span key={idx}>
                  {getSubType(val) + (subAglTypes.length > 1 ? ', ' : ' ')}
                </span>
              ))}
              그룹
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ProfileContainer>
  );
};

export default Page1;
