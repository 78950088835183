const canvasId = 'pdf-content';
const publicUrl = process.env.PUBLIC_URL; // PUBLIC_URL 환경 변수 가져오기

const profilePages = {
  2: `${publicUrl}/images/KEMIprofile2.jpg`,
  3: `${publicUrl}/images/KEMIprofile3.jpg`,
  4: `${publicUrl}/images/KEMIprofile4.jpg`,
  5: `${publicUrl}/images/KEMIprofile5.jpg`,
  6: `${publicUrl}/images/KEMIprofile6.jpg`,
};
const kemiLogoSrc = `${publicUrl}/images/KEMILOGO.jpg`;

export { canvasId, profilePages, kemiLogoSrc };
