import { Box } from '@mui/material';

const ProfilePages = ({ src }) => {
  return (
    <Box
      component="img"
      sx={{
        maxWidth: '100%',
        height: 'auto',
      }}
      src={src}
      alt="Responsive Image"
    />
  );
};

export default ProfilePages;
