import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { profilePages } from '../utils/constants';

const styles = {
  container: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
  },
  button: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: '24px',
    width: '40px',
    height: '40px',
  },
};

const handleDownloadPdf = async (id) => {
  const canvas = await html2canvas(document.getElementById(id));
  const data = canvas.toDataURL('image/png');

  const pdf = new jsPDF({
    format: 'a4', // 필요한 경우 다른 포맷으로 변경
  });
  const imgProperties = pdf.getImageProperties(data);
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const pdfWidth = pdf.internal.pageSize.getWidth();

  // 수정된 이미지 너비 계산
  const scaledWidth = (imgProperties.width * pdfHeight) / imgProperties.height;

  // 중앙 정렬을 위한 xOffset 계산
  const xOffset = (pdfWidth - scaledWidth) / 2;
  pdf.addImage(data, 'PNG', xOffset, 0, scaledWidth, pdfHeight);

  // 이미지 URL 목록
  const imageUrls = Object.values(profilePages);

  // 이후 페이지 추가
  for (const imageUrl of imageUrls) {
    const img = new Image();
    img.src = imageUrl;
    await new Promise((resolve) => {
      img.onload = () => {
        pdf.addPage();
        pdf.addImage(img, 'JPEG', xOffset, 0, scaledWidth, pdfHeight);
        resolve();
      };
    });
  }

  pdf.save('download.pdf');
};

const PdfButton = ({ canvasId }) => {
  return (
    <div style={styles.container}>
      <div style={styles.button} onClick={() => handleDownloadPdf(canvasId)}>
        PDF
      </div>
    </div>
  );
};

export default PdfButton;
