import { functions, httpsCallable } from './firebaseFunctions';

const apiCall = ({ functionName, data = null, onSuccess, onError }) => {
  const callable = httpsCallable(functions, functionName);
  callable(data)
    .then((response) => {
      console.log('response', response, 'functionName', functionName);
      if (onSuccess) onSuccess(response.data);
    })
    .catch((error) => {
      if (onError) onError(error);
    });
};

export default apiCall;
