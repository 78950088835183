import React, { useEffect } from 'react';
import useStore from '../store';
import { Box, Button, Grid, OutlinedInput, Stack } from '@mui/material';
import AuthBackground from './auth/AuthBackground';
import AuthCard from './auth/AuthCard';

const LicenseInputPage = () => {
  const { licenseKey, setLicenseKey, api } = useStore((state) => state);

  useEffect(() => {
    // URLSearchParams를 사용하여 쿼리 파라미터 추출
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get('licenseKey');

    if (key) {
      setLicenseKey(key);
    }
  }, []);

  const handleInputChange = (event) => {
    setLicenseKey(event.target.value);
  };

  const handleSubmit = () => api.validateLicenseKey(licenseKey);

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <AuthBackground />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{
          minHeight: '100vh',
        }}
      >
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              minHeight: {
                xs: 'calc(100vh - 134px)',
                md: 'calc(100vh - 112px)',
              },
            }}
          >
            <Grid item>
              <AuthCard>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <OutlinedInput
                        placeholder="license key"
                        fullWidth
                        value={licenseKey}
                        onChange={handleInputChange}
                      />
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit}
                      >
                        라이센스 키 입력
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </AuthCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}></Grid>
      </Grid>
    </Box>
  );
};

export default LicenseInputPage;
