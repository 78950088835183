import Page1 from './profile/Page1';
import ProfilePages from './profile/ProfilePages';
import PdfButton from '../components/PdfButton';
import { canvasId, profilePages } from '../utils/constants';
import { useEffect } from 'react';

function ResultPage() {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Page1 canvasId={canvasId} />
      <ProfilePages src={profilePages['2']} />
      <ProfilePages src={profilePages['3']} />
      <ProfilePages src={profilePages['4']} />
      <ProfilePages src={profilePages['5']} />
      <ProfilePages src={profilePages['6']} />
      <PdfButton canvasId={canvasId} />
    </>
  );
}

export default ResultPage;
