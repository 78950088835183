import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import apiCall from './utils/apiCall';
import _ from 'lodash';

const store = (set) => ({
  questions: [],
  result: {},
  user: { name: '', email: '' },
  isValidLicense: false,
  testCompleted: false,
  licenseKey: '',
  setLicenseKey: (licenseKey) => set({ licenseKey }),
  scores: {},
  setScores: (scores) => set({ scores }),
  api: {
    getQuestions: (licenseKey) => {
      apiCall({
        functionName: 'getQuestions',
        data: { licenseKey },
        onSuccess: ({ questions }) => set({ questions }),
        onError: (error) => {
          console.log(error);
          set({ questions: [] });
        },
      });
    },
    makeResult: (scores, licenseKey) => {
      apiCall({
        functionName: 'getResult',
        data: { scores, licenseKey },
        onSuccess: ({ result }) => {
          set({ result });
          set({ testCompleted: true });
          localStorage.setItem('_qscs', JSON.stringify({}));
        },
        onError: (error) => {
          console.log(error);
          set({ result: {} });
          alert('오류가 발생했습니다.');
        },
      });
    },
    validateLicenseKey: (licenseKey) => {
      apiCall({
        functionName: 'validateLicenseKey',
        data: { licenseKey },
        onSuccess: ({ user, result }) => {
          set({ isValidLicense: true, user, result });
          if (!_.isEmpty(result)) set({ testCompleted: true });
        },
        onError: (error) => {
          set({ isValidLicense: false });
          console.log(error);
          alert('유효하지 않은 라이센스 키입니다.');
        },
      });
    },
  },
});

const useStore = create(
  process.env.NODE_ENV !== 'production' ? devtools(store) : store,
);
export default useStore;
