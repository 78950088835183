import { styled } from '@mui/material/styles';
import { Box, Chip } from '@mui/material';

const CenteredBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '16px',
});

const LabelChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const ProfileLabelTitle = ({ title }) => {
  return (
    <CenteredBox>
      <LabelChip label={title} variant="outlined" color="primary" />
    </CenteredBox>
  );
};

export default ProfileLabelTitle;
