import React from 'react';
import './App.css'; // CSS 파일 임포트
import TestPage from './pages/TestPage';
import LicenseInputPage from './pages/LicenseInputPage';
import useStore from './store';
import ResultPage from './pages/ResultPage';

const App = () => {
  const { isValidLicense, testCompleted } = useStore((state) => state);
  // todo
  //  2.
  //   //여기까지만 하고 enneagram.kr도메인 붙여서 오픈.
  //  우선은 네이버익스퍼트, 숨고 크몽에서 장사 시작.
  //  그 다음 검사중간에 저장되도록 로컬스토리지나 쿠키 활용...
  //  나중에 할것들...
  //  일단 검사 구매하기 구조도 만들고 결제 붙이기 전에는  - 신청페이지 필요
  //  실질적으론 테스터들에게만 링크관련 정보 줄거니까....일단은..
  //  이후 어드민화면에서 라이센스 발급 및 관리 가능하도록
  //  마스터 계정과 전문가계정을 별도로 작업하기...
  //  마스터 계정은 전문가 계정을 관리할 수 있도록...
  //  전문가 계정은 라이센스 발급 및 관리 가능하도록
  //  그룹 검사나 단체 검사는 어떻게 처리할지...? 대상자들 연락처를 입력받은 후 라이센스 단체 발송?
  //  결과지 pdf 메일 전송??
  //  4.영어버전 만들기.... 딥엘로 번역후에 누나에게 검수요청...이후에 영어권도 판매..

  const renderComponent = () => {
    if (!isValidLicense) {
      return <LicenseInputPage />;
    } else if (!testCompleted) {
      return <TestPage />;
    } else {
      return <ResultPage />;
    }
  };

  return <div className="app">{renderComponent()}</div>;
};

export default App;
