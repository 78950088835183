import { initializeApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDA4YPG6CgKUjvvlDrFSUPePE1ol2f8VAk',
  authDomain: 'kei-functions.firebaseapp.com',
  projectId: 'kei-functions',
  storageBucket: 'kei-functions.appspot.com',
  messagingSenderId: '265203188972',
  appId: '1:265203188972:web:c198fdd1d23a0cb66451c9',
  measurementId: 'G-ML4SBDSJPS',
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, 'asia-northeast3');
if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { functions, httpsCallable };
